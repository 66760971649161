#modalcontent .modal-content{
  background-color: #707070;
}
#modaleditor .modal-content{
  background-color: #fff;
  width: 1140px;
}
.spinner-grow.text-success{ 
  background-color: greenyellow;
}
.w50  {
  width: 50%;
}
.ck-editor__editable {
  min-height: 300px;
}
.dt-layout-row  {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.dt-start, .dt-end{
  flex: 1 0 0%;
}
.dt-end{
  text-align: end;
}
.dt-length label{
  text-transform: capitalize;
  margin-left: 0.25rem;
}
.dt-search label{
  margin-right: 0.25rem;
}

.dt-paging .dt-paging-button, .mybtn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dt-paging .dt-paging-button.current, .mybtn {  
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  color: #fff;
}

.btnlite{
  border: var(--bs-btn-border-width) solid #0d6efd;
  border-radius: var(--bs-btn-border-radius);
  background-color: #0d6efd;
  color: #fff;
  padding: 0.25rem 0.5rem;
}

/*
 * Globals
 */


/* Custom default button */
.btn-light,
.btn-light:hover,
.btn-light:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.cover-container {
  max-width: 42em;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: 3%;
  right: auto;
  bottom: 0;
  left: 5%;
  height: 480px;
  background-color: #B8860B;
}

.product-device-2::before {
  background-color: #000;
  opacity: .1;
}

.bd-gray-400 {
  color: #000;
  background-color: #ced4da;
}

.bd-gray-500 {
  color: #000;
  background-color: #adb5bd;
}

.bd-cyan-700 {
  color: #fff;
  background-color: #087990;
}

.bd-cyan-800 {
  color: #fff;
  background-color: #055160;
}

.bd-orange-600 {
  color: #fff;
  background-color: #ca6510;
}

.bd-orange-700 {
  color: #fff;
  background-color: #984c0c;
}

#sliderTitle {
  width: 92%;
  margin: auto;
    margin-top: auto;
  font-size: 20px;
  letter-spacing: 1px;
  margin-top: 7%;
}

#sliderContainer {
  width: 92%;
  margin: auto;
}

#carouselExampleControls {
  height: fit-content;
  width: 100%;
}
.margin {
  margin: auto;
  width: 100%;
}
.smallFont {
  font-size: 0.7em;
}
.cards {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.carousel-control-next, .carousel-control-prev{
  background: #000;
  color: #fff;
  height: 15%;
  top: 35%;
  width: 5%;
}

/*
 * Extra utilities
 */

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } 
} */
